
  export default {
    components: {
      PageContent: () => import("~/components/home/PageContent"),
      AdsModal: () => import("~/components/global/modal/AdsModal"),
    },
    data: () => ({
      loaded: false,
      payload: {
        hero: null,
        affiliates: null,
        product_categories: null,
        about_us: null,
        testimonials: null,
        news: null,
        ads: null,
      },
      toggle: {
        hmAds: true,
      },
    }),
    methods: {
      initialize ()
      {
        setTimeout(() =>
        {
          this.websiteChecker(["hmAds"], this);
        }, 300);
      },
    },
    mounted ()
    {
      this.toggleModalStatus({type: "loader", status: true});
      this.$axios
        .$get("web/pages/home")
        .then(({res}) =>
        {
          this.payload = {
            hero: res.hero,
            affiliates: res.affiliates,
            product_categories: res.products,
            about_us: res.about_us,
            testimonials: res.testimonials,
            news: res.news,
            ads: res.ads,
          };
        })
        .catch((err) =>
        {
          this.$nuxtthis.$nuxt.error({
            statusCode: 500,
            message: "Something Went Wrong",
          });
        })
        .then(() =>
        {

          this.initialize();
          this.loaded = true;
          setTimeout(() =>
          {
            this.toggleModalStatus({type: "loader", status: false});
          }, 600);
        });
    },
    head ()
    {
      let host = process.env.BASE_URL;
      return {
        title: "The Largest Insurance Company in the Philippines | Malayan Insurance Philippines",
        link: [
          {
            href: `https://malayan.com${ this.$route.fullPath }`,
          },
          {
            rel: "canonical",
            href: `https://malayan.com${ this.$route.fullPath }`,
          },
        ],
        meta: [
          {
            name: "keywords",
            content: "insurance company philippines, non life insurance company, non life insurance company philippines, non life insurer, insurance company in the philippines, affordable travel insurance, affordable travel insurance philippines, budget travel insurance, insurance for budget travel, affordable travel insurance online, vehicle insurance philippines, motor vehicle insurance, motor vehicle insurance philippines, car and motorcycle insurance, car and motorbike insurance, Car insurance philippines, car insurance online application, apply for auto insurance online, comprehensive car insurance philippines, comprehensive car insurance, insurance for motorcycle philippines, comprehensive motorcycle insurance, bike insurance apply online, motorcycle insurance, motorcycle insurance online",
          },
          {
            hid: "og:title",
            property: "og:title",
            content: "The Largest Insurance Company in the Philippines | Malayan Insurance Philippines",
          },
          {
            hid: "og:description",
            property: "og:description",
            content:
              "Malayan Insurance Philippines is the largest non-life insurer in the country. Protect yourself and your loved ones with our products today.",
          },
          {
            name: "description",
            hid: "description",
            property: "description",
            content:
              "Malayan Insurance Philippines is the largest non-life insurer in the country. Protect yourself and your loved ones with our products today.",
          },
          {
            hid: "og:url",
            property: "og:url",
            content: `https://malayan.com${ this.$route.fullPath }`,
          },
          {hid: "og:image", property: "og:image", content: "/cbimage.webp"},
          {hid: "og:image:alt", property: "og:image:alt", content: "Malayan Insurance"},
          {hid: "og:site_name", property: "og:site_name", content: "Malayan Insurance"},
          {hid: "og:type", property: "og:type", content: "website"},
          {
            hid: "og:site_name",
            property: "og:site_name",
            content: "Malayan Insurance",
          },
        ],
      };
    },
  };
